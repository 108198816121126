import { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as authActions from 'state/auth/actions';

import {
  useInputValue,
  useTranslation,
  useFocusOnMount,
  useTitle,
} from 'hooks';

import AuthLayout from 'components/AuthLayout';
import Input from 'components/Forms/InputSimple';
import Button from 'components/Button';

import PasswordRecovered from './PasswordRecovered';
import locales from './i18n';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  useTitle(t('Password recovery'));

  const emailEl = useFocusOnMount();
  const [recovering, setRecovering] = useState(false);
  const [recovered, setRecovered] = useState(false);
  const email = useInputValue('');
  const [error, setError] = useState(null);

  const recover = async () => {
    try {
      setRecovering(true);
      await dispatch(authActions.recover(email.value.trim()));
      setRecovered(true);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('UNKOWN');
      }

      setRecovering(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') recover();
  };

  if (recovered) return <PasswordRecovered />;
  if (error) {
    if (error === 'EXISTING_ACTION') {
      return (
        <AuthLayout
          title={t('Couldn\'t recover your password')}
          subtitle={t('You asked for a password reset before. Please check your e-mail for instructions, or wait to try again.')}
        />
      );
    }

    if (error === 'NOT_FOUND') {
      return <PasswordRecovered />;
    }

    return (
      <AuthLayout
        title={t('There was a problem')}
        subtitle={t('We couldn\'t send you a recover link to your e-mail.')}
      />
    );
  }

  return (
    <AuthLayout
      title={t('Password recovery')}
      subtitle={t('Enter your username or e-mail and we will send you the instructions to reset your password.')}
    >
      <Input
        ref={emailEl}
        placeholder={t('Username or E-mail')}
        {...email}
        disabbled={recovering}
        onKeyPress={handleKeyPress}
        withBorder
      />

      <Button
        onClick={recover}
        loading={recovering}
        content={t('Recover')}
      />
    </AuthLayout>
  );
};

export default ForgotPassword;
